import React from 'react'
import { graphql, Link } from 'gatsby'
import BasicLayout from 'src/components/BasicLayout'

function MdxIndex({ data }) {
  const { edges: lessons } = data.allMdx
  return (
    <BasicLayout>
      {lessons.map(({ node }) => {
        const { title, section, tags } = node.frontmatter
        //if (title.length > 0 && tags !== null && !tags.includes("tutorial")) {
        if (title.length > 0) {
          const htmlSnippet = node.fields.excerpt_html.replace(/<\!--.*?-->/g, "").replace(/[[<]p>import.*/g, "")
          return (
            <div key={node.id}>
              <h4><Link to={`${node.fields.slug}`}>{title}</Link></h4>
              <div className="snippet-container">
                <p>{node.excerpt}</p>
                {/* <div dangerouslySetInnerHTML={{ __html: htmlSnippet }}/> */}
              </div>
              {/* <div>{node.frontmatter.tags}</div> */}
              <hr />
            </div>
          )
        }
      })}
    </BasicLayout>
  )
}

export default MdxIndex

export const pageQuery = graphql`
  query snlpTwoIndex {
    allMdx(sort: {fields: frontmatter___title, order: ASC}, filter: {frontmatter: {section: {eq: "snlp-2"}}}) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          fields { slug, excerpt_html }
          frontmatter {
            title
            section
            tags
          }
        }
      }
    }
  }
`
